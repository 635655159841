<template>
  <layout-vertical>
    <router-view v-if="isRouterAlive" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  computed: {
    isRouterAlive() {
      return this.$store.state.verticalMenu.isRouterAlive;
    },
  },
};
</script>
