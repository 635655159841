<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-1"
    menu-class="dropdown-menu-media"
    right
    v-b-tooltip.hover.bottom="'通知'"
  >
    <template #button-content>
      <feather-icon
        :badge="unreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">未讀聯絡人</h4>
        <b-badge pill variant="light-primary">
          {{ unreadContactCount }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in contactAndLastChat"
        :key="notification.userId"
        :to="{
          name: 'apps-chat-contact',
          params: { contactId: notification.userId },
        }"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.userAvatarUrl"
              :text="notification.userAvatarUrl"
            />
          </template>
          <div class="d-flex">
            <div class="notification-main mr-auto text-truncate">
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.userName }}
                </span>
              </p>
              <small class="notification-text">
                {{ notification.messageText }}
              </small>
            </div>
            <div class="notification-right">
              <small class="mb-25 notification-text">
                {{
                  formatDateToMonthShort(notification.createTime, {
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}
              </small>
              <b-badge class="float-right" pill variant="primary">
                {{ notification.unReadCount }}
              </b-badge>
            </div>
          </div>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        @click="readAllMessage"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        >將所有訊息設為已讀</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import { computed } from "@vue/composition-api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { formatDateToMonthShort } from "@core/utils/filter";
import axios from "@axios";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const unreadCount = computed(() => {
      if (!store.state.chat.contactsAndLastChat.length) return 0;
      // 未讀訊息數
      let unreadCounts = store.state.chat.contactsAndLastChat.map(
        (c) => c.unReadCount
      );

      // 未讀訊息數加總
      return unreadCounts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });
    });

    const unreadContactCount = computed(() => {
      if (!store.state.chat.contactsAndLastChat.length) return 0;
      return store.state.chat.contactsAndLastChat.filter(
        (c) => c.unReadCount > 0
      ).length;
    });

    const contactAndLastChat = computed(() => {
      return store.state.chat.contactsAndLastChat.filter(
        (c) => c.unReadCount > 0
      );
    });

    const readAllMessage = () => {
      axios.post('api/chat/ContactRead').then(() => {
        store.state.chat.contactsAndLastChat.forEach((element) => {
          element.unReadCount = 0;
        });
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      unreadCount,
      unreadContactCount,
      contactAndLastChat,
      perfectScrollbarSettings,
      formatDateToMonthShort,
      readAllMessage,
    };
  },
};
</script>

<style scoped>
.notification-main {
  width: 250px;
}

.notification-right small {
  display: block;
}

.dropdown-notification a {
  color: #b9b9c3;
}

@media (max-width: 767px) {
  .notification-main {
    width: 200px;
  }
}
</style>
