<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <li class="nav-item">
        <a
          @click="logout"
          href="javascript:void(0)"
          class="nav-link"
          v-b-tooltip.hover.bottom="'登出'"
        >
          <feather-icon icon="LogOutIcon" size="21" />
        </a>
      </li>
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <li class="nav-item">
        <a
          href="javascript:void(0)"
          class="nav-link"
          v-b-tooltip.hover.bottom="'靜音'"
        >
          <feather-icon icon="Volume2Icon" size="21" />
        </a>
      </li> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <notification-dropdown />
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant
      >
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>

      <!-- <user-dropdown /> -->
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BAvatar, VBTooltip } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
// import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
// import DarkToggler from "./components/DarkToggler.vue";
// import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
// import UserDropdown from "./components/UserDropdown.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import { hubStop } from '@/hubs/hub';

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    BAvatar,
    // Locale,
    SearchBar,
    // DarkToggler,
    // CartDropdown,
    NotificationDropdown,
    // UserDropdown,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // 切斷 signalr 連線
      hubStop();

      // 移除 store 中的資料
      store.dispatch('chat/resetState');

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
